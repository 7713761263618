<template>
	<div class="invite-index-page">
		
		<van-form @submit="onSubmit" class="content" ref="formItem" :show-error-message="false">

			<!-- <p style="text-align: center;font-size: 18px;padding-bottom: 15px">{{translate('verify_phone')}}</p> -->
			<p style="text-align: center;font-size: 18px;padding-bottom: 15px">验证邮箱</p>

			<van-row>
				<van-col span="10">
					<van-field
						v-model="formItem.country_code_label"
						readonly
						@click="showPicker = true"
						style="border-radius: 24px!important;margin-bottom:15px;background: #f0f4ff"
					/>
					<van-popup v-model="showPicker" round position="bottom">
						<van-picker
							show-toolbar
							:columns="country_codes"
							@cancel="showPicker = false"
							@confirm="onConfirm"
						/>
					</van-popup>
				</van-col>

				<van-col span="14">
					<van-field
						v-model="formItem.telephone"
						type="tel"
						:placeholder="translate('please_enter_the_phone_number')"
						:rules="[{ required: true, trigger: 'onSubmit', message: translate('please_fill_in_cell_phone_number') }]"
						style="border-radius: 24px!important;margin-bottom:15px;background: #f0f4ff"
					/>
				</van-col>
			</van-row>

			<!-- <van-field
				v-model="formItem.sms_code"
				type="number"
				:placeholder="translate('please_enter_verification_code')"
				:rules="[{ required: true, trigger: 'onSubmit', message: translate('please_fill_verification_code') }]"
				style="border-radius: 24px!important;margin-bottom:15px;background: #f0f4ff"
			>
				<template #button>
					<van-button size="mini" type="primary" block native-type="button" :disabled="disabledSendCode" @click="sendSMSCode">{{send_text}}</van-button>
				</template>
			</van-field> -->
			<van-row>
				<van-col span="24">
					<van-field
						v-model="formItem.email"
						type="tel"
						placeholder="请输入邮箱"
						:rules="[{ required: true, trigger: 'onSubmit', message: '请输入邮箱' }]"
						style="border-radius: 24px!important;margin-bottom:15px;background: #f0f4ff"
					/>
				</van-col>
			</van-row>
			<van-row>
				<van-field
					v-model="formItem.email_code"
					type="number"
					placeholder="请输入邮箱验证码"
					:rules="[{ required: true, trigger: 'onSubmit', message: '请输入邮箱验证码' }]"
					style="border-radius: 24px!important;margin-bottom:15px;background: #f0f4ff"
				>
					<template #button>
						<van-button size="mini" type="primary" block native-type="button" :disabled="disabledSendCode" @click="sendEmailCode">{{send_text}}</van-button>
					</template>
				</van-field>
			</van-row>
			<van-row>
				<van-col span="24">
					<!-- <van-button round block color="#FFAC00" native-type="submit">{{translate('invite_next_step')}}</van-button> -->
					<van-button round block color="#FFAC00">{{translate('invite_next_step')}}</van-button>
				</van-col>
			</van-row>

			<van-overlay :show="isWeiXin">
				<div class="wrapper">
					<div class="weixin-browser"></div>
				</div>
			</van-overlay>
	
		</van-form>

		
	</div>
</template>

<script>
	export default {
		name: 'invite_index',
		data() {
			return {
				showPicker: false,

				formItem: {
					invite_id: "",
					esop_team_id: 0,
					code: "",
					country_code: "",
					country_code_label: "",
					telephone: "",
					sms_code: "",
					email: "",
					email_code: "",
				},

				send_text: this.translate('send_the_verification_code'),
				disabledSendCode: false,

				second: 60,
				clock: "",

				resourceURL: this.$axios.defaults.resourceURL,

				isWeiXin: false,

				country_codes: [],
				
			}
		},
		mounted() {
			if (this.$route.query.code == "" || this.$route.query.code == undefined) {
				this.$router.replace('/nologin')
			} else {
				this.formItem.code = this.$route.query.code

				if (this.$route.query.team == undefined) {
					this.formItem.esop_team_id = 0
				}else{
					this.formItem.esop_team_id = this.$route.query.team
				}
			}

			this.$axios({
				method: 'post',
				url: 'invite/dicts', 
			}).then ((res) => {
				if (res.success) {

					let country_codes = []

					res.data.country_codes.forEach(item => {

						item = {
							value: item.code,
							text: item.name + ' ' + item.code
						}

						country_codes.push(item)
						if(item.value == '86') {
							this.onConfirm(item)
						}						
					})

					this.country_codes = country_codes

				} else {
					this.$toast.fail(res.error_text)
				}
			})

			//window.navigator.userAgent属性包含了浏览器类型、版本、操作系统类型、浏览器引擎类型等信息，这个属性可以用来判断浏览器类型
			var ua = window.navigator.userAgent.toLowerCase();
			//alert(JSON.stringify(ua))
			//通过正则表达式匹配ua中是否含有MicroMessenger字符串
			if(ua.match(/MicroMessenger/i) == 'micromessenger'){
				this.isWeiXin = true
			} else{
				this.isWeiXin = false
			}
		},
		methods: {

			onConfirm(item) {
				this.formItem.country_code = item.value
				this.formItem.country_code_label = item.text

				console.log(item)

				this.showPicker = false
			},

			doLoop() {
				this.second--;
				if(this.second > 0){
					this.send_text = this.second + this.translate('invite_second')
				}else{
					clearInterval(this.clock); //清除js定时器
					this.disabledSendCode = false;
					this.send_text = this.translate('send_the_verification_code');
					this.second = 60; //重置时间
				}
			},
			sendEmailCode() {

				if(this.formItem.email == ""){
					this.$toast.fail('请输入邮箱')
					return false
				}

				this.$axios({
					method: 'post',
					url: 'invite/sendemailcode', 
					data: this.formItem
				}).then ((res) => {
					if (res.success && res.data.invite_id) {
						this.formItem.invite_id = res.data.invite_id

						this.$toast.success(this.translate('send_the_verification_code'))

						this.disabledSendCode = true

						this.send_text = this.second + this.translate('invite_second')
						
						this.clock = setInterval(this.doLoop, 1000); //一秒执行一次
					} else if (res.error_text == this.translate('phone_registered')) {
						this.$dialog.confirm({
							message: this.translate('phone_registered_to_login'),
							confirmButtonText: this.translate('go_download')
						}).then(() => {
							this.$router.replace('/invite/download')
						}).catch(() => {

						})
					} else {
						this.$toast.fail(res.error_text)
					}
				})
				},
			onSubmit() {
				if(this.formItem.invite_id == ""){
					this.$toast.fail(this.translate('data_error_please_refresh'))
					return false
				}

				this.$axios({
					method: 'post',
					url: 'invite/checkemailcode',
					data: this.formItem
				}).then ((res) => {
					if (res.success) {
						this.$router.replace('/invite/password?invite_id=' + this.formItem.invite_id)
					}else{
						this.$toast.fail(res.error_text)
					}
				})
			}

			// sendSMSCode() {

			// 	if(this.formItem.telephone == ""){
			// 		this.$toast.fail(this.translate('please_enter_valid_phone_number'))
			// 		return false
			// 	}

			// 	this.$axios({
			// 		method: 'post',
			// 		url: 'invite/sendsmscode', 
			// 		data: this.formItem
			// 	}).then ((res) => {
			// 		if (res.success && res.data.invite_id) {
			// 			this.formItem.invite_id = res.data.invite_id

			// 			this.$toast.success(this.translate('send_the_verification_code'))

			// 			this.disabledSendCode = true

			// 			this.send_text = this.second + this.translate('invite_second')
						
			// 			this.clock = setInterval(this.doLoop, 1000); //一秒执行一次
			// 		} else if (res.error_text == this.translate('phone_registered')) {
			// 			this.$dialog.confirm({
			// 				message: this.translate('phone_registered_to_login'),
			// 				confirmButtonText: this.translate('go_download')
			// 			}).then(() => {
			// 				this.$router.replace('/invite/download')
			// 			}).catch(() => {

			// 			})
			// 		} else {
			// 			this.$toast.fail(res.error_text)
			// 		}
			// 	})
			// },
			
			// onSubmit() {
			// 	if(this.formItem.invite_id == ""){
			// 		this.$toast.fail(this.translate('data_error_please_refresh'))
			// 		return false
			// 	}

			// 	this.$axios({
			// 		method: 'post',
			// 		url: 'invite/checksmscode',
			// 		data: this.formItem
			// 	}).then ((res) => {
			// 		if (res.success) {
			// 			this.$router.replace('/invite/password?invite_id=' + this.formItem.invite_id)
			// 		}else{
			// 			this.$toast.fail(res.error_text)
			// 		}
			// 	})
			// }
		},
	}
</script>

<style scoped>
.send-code-btn {
	display:inline-block;
	text-align:right;
	color:#FDF773;
	transform: translateY(10px);
}
</style>